<template>
  <div>
    <!--卡片视图区域-->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="20">
        <el-col :span="6">
          <el-input placeholder="手机号精确查找" v-model="queryInfo.mobile" @keyup.enter.native="getList" clearable
                    @clear="getList">
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
      <!--用户列表区域-->
      <el-table :data="list" border stripe>
        <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
        <el-table-column prop="uid" label="用户ID" width="150" align="center"></el-table-column>
        <el-table-column prop="nickname" label="昵称" width="130"></el-table-column>
        <el-table-column label="用户头像" width="130" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.avatar !== ''"
              style="height: 60px;cursor: pointer"
              :src="scope.row.avatar"
              :preview-src-list="[scope.row.avatar]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="电话" width="150" align="center"></el-table-column>
        <el-table-column label="性别" width="80">
          <template slot-scope="scope">
            <el-tag type="danger" v-if="scope.row.gender === 0">未设置</el-tag>
            <el-tag type="success" v-if="scope.row.gender === 1">男</el-tag>
            <el-tag type="info" v-if="scope.row.gender === 2">女</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="coins" label="当前金币数" width="150" align="center"
                         style="color: red;font-weight: bold"></el-table-column>
        <el-table-column prop="city" label="城市" width="150" align="center"></el-table-column>
        <el-table-column prop="birthday" label="生日" width="130" align="center"></el-table-column>
        <el-table-column label="注册来源" width="120" align="center">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.register_source === 1">短信验证码</el-tag>
            <el-tag type="info" v-if="scope.row.register_source === 2">手机一键登录</el-tag>
            <el-tag type="info" v-if="scope.row.register_source === 3">苹果登录</el-tag>
            <el-tag type="info" v-if="scope.row.register_source === 4">微信登录</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="状态" width="80" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" @change="changeUserStatus(scope.row.uid, scope.row.status)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180" align="center"></el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改用户基本信息" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showEditDialog(scope.row)">修改
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :key="queryInfo.page"
        :total="total">
      </el-pagination>
    </el-card>
    <!--修改用户的对话框-->
    <el-dialog
      title="修改用户"
      :visible.sync="editDialogVisible"
      @close="editDialogClose"
      :close-on-click-modal="false"
      width="30%">
      <el-form ref="editFormRef" :model="editForm" :rules="editFormRules" label-width="70px">
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="editForm.nickname" readonly></el-input>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="editForm.email" readonly></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="editForm.mobile" readonly></el-input>
        </el-form-item>
        <el-form-item label="用户头像">
          <template>
            <img :src="editForm.avatar" width="200px" alt="暂无用户头像" height="200px" style="object-fit: cover">
          </template>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="editUserInfo" disabled>确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>

// 导入组件
export default {
  name: 'Users',
  data () {
    // 验证邮箱的校验规则
    const checkEmail = (rule, value, cb) => {
      // 验证邮箱的正则表达式
      const regEmail = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
      if (regEmail.test(value)) {
        // 合法邮箱
        return cb()
      }
      // 校验不通过，代表是非法的邮箱
      cb(new Error('请输入合法的邮箱'))
    }
    // 验证手机号的验证规则
    const checkMobile = (rule, value, cb) => {
      const regMobile = /^(1[3-9])\d{9}$/
      if (regMobile.test(value)) {
        // 合法邮箱
        return cb()
      }
      // 校验不通过，代表是非法的手机号
      cb(new Error('请输入合法的手机号'))
    }
    return {
      // 获取用户列表的参数
      queryInfo: {
        mobile: '',
        // 当前的页数
        page: 1,
        limit: 10
      },
      list: [],
      total: 0,
      // 控制修改用户对话框的显示与隐藏
      editDialogVisible: false,
      // 查询到的用户对象
      editForm: {
        nickname: '',
        password: '',
        email: '',
        mobile: ''
      },
      // 修改表单的验证规则对象
      editFormRules: {
        nickname: [
          {
            require: true,
            message: '昵称为必填项',
            trigger: 'blur'
          },
          {
            min: 2,
            max: 12,
            message: '昵称的长度在2到12个字符之间',
            trigger: 'blur'
          }
        ],
        email: [
          {
            min: 6,
            max: 64,
            message: '邮箱的长度在6到64个字符之间',
            trigger: 'blur'
          },
          {
            validator: checkEmail,
            trigger: 'blur'
          }
        ],
        mobile: [
          {
            validator: checkMobile,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    // 获取当前路由参数中的page
    const page = parseInt(this.$route.query.page ?? 1)
    this.queryInfo.page = page > 0 ? page : 1
    // 获取当前路由参数中的limit
    const limit = parseInt(this.$route.query.limit ?? 10)
    this.queryInfo.limit = (limit > 0 && limit < 100) ? limit : 10
    this.getList()
  },
  methods: {
    async getList () {
      const { data: res } = await this.$http.get('/user/list', { params: this.queryInfo })
      if (res && res.code === 0) {
        this.list = res.data.data.map(item => {
          // status的值为1时，显示为正常，否则显示为禁用
          item.status = item.status === 1
          return item
        })
        this.total = res.data.total
      }
    },
    // 监听pageSize的改变
    handleSizeChange (newSize) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          limit: newSize
        }
      })
      // 更新查询参数
      this.queryInfo.limit = newSize
      this.getList()
    },
    // 监听页码值的改变
    handleCurrentChange (newPage) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
      // 更新查询参数
      this.queryInfo.page = newPage
      this.getList()
    },
    // 监听Switch开关状态的改变
    async changeUserStatus (uid, status) {
      // 改变用户
      const { data: res } = await this.$http.post('/user/changeUserStatus',
        {
          uid: uid,
          status: status ? 1 : 0
        })
      if (res && res.code === 0) {
        this.$message.success('状态变更成功')
      } else {
        this.$message.success('状态变更失败:' + res.msg)
        this.getList().then(r => {
        })
      }
    },
    // 展示编辑用户的对话框
    async showEditDialog (user) {
      this.editForm = user
      this.editDialogVisible = true
    },
    // 修改用户信息并提交
    editUserInfo () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return
        }
        // 发起修改用户信息的数据请求
        const { data: res } = await this.$http.put('/admin/users/' + this.editForm.id, {
          nickname: this.editForm.nickname,
          email: this.editForm.email,
          mobile: this.editForm.mobile,
          level: this.editForm.level
        })
        if (res && res.code === 0) {
          // 关闭对话框
          this.editDialogVisible = false
          // 刷新数据列表
          this.getList().then(r => {
          })
          // 提示修改成功
          this.$message.success('用户信息更新成功')
        }
      })
    },
    // 监听编辑用户对话框的关闭事件
    editDialogClose () {
      this.$refs.editFormRef.resetFields()
    }
  }
}
</script>

<style lang="less" scoped>

</style>

<template  style="overflow-y:auto; overflow-x:auto; height: calc(100vh - 120px)">
  <div>
    <el-card class="box-card">
      <el-form ref="form" label-width="120px">
        <div v-for="(contentItem, contentItemIndex) in content" :key="contentItemIndex" style="border-bottom: #4A5064 solid 1px;margin-top: 20px; margin-bottom: 20px">
          <el-form-item label="红包序号">
             <el-tag> 红包任务{{contentItemIndex + 1}} </el-tag>
          </el-form-item>
          <el-row :gutter="10">
            <el-col :span="4">
              <el-form-item label="任务序号">
                <el-input type="number" v-model="contentItem.sort" placeholder="请填写整型数值" disabled></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-tooltip placement="top">
                <div slot="content" class="tooltip_text">
                  <span style="font-size: 13px;line-height: 2em">任务序号：</span><br>
                  - 控制任务在客户顿显示的顺序<br>
                  - 数值越小，排序越优先</div>
                <el-button>查看说明</el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="4">
              <el-form-item label="任务总数">
                <el-input type="number" v-model="contentItem.task_num" placeholder="请填写整型数值"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-tooltip placement="top">
                <div slot="content" class="tooltip_text">
                  <span style="font-size: 13px;line-height: 2em">任务总数：</span><br>
                  - 控制用户每天总共能执行的次数</div>
                <el-button>查看说明</el-button>
              </el-tooltip>
            </el-col>
          </el-row>
          <el-form-item label="任务列表">
            <el-row style="margin: 5px">
              <el-button icon="el-icon-circle-plus" type="success" circle @click="addTaskItem(contentItemIndex)"></el-button>
            </el-row>
            <FloatRedPacketItem v-for="(item) in contentItem.task_list"
                                @update="updateTaskItem(contentItemIndex)"
                                :key="item.task_id"
                                :obj="item"
                                :tasks="tasks"
                                :contentIndex="contentItemIndex"
                                :disableItems="contentItem.task_id_arr"
                                :iconUrl="qiniu_cdn + item.icon"
                                @delete="deleteTaskItem(contentItemIndex, item)"></FloatRedPacketItem>
          </el-form-item>
          <el-form-item label="默认任务">
            <el-select v-model="contentItem.default_task_id" placeholder="请选择默认的任务">
              <el-option v-for="(val, index) in contentItem.task_id_arr" :key="val" :label="val" :value="index"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import FloatRedPacketItem from '../../components/FloatRedPacketItem'
export default {
  components: { FloatRedPacketItem },
  data () {
    return {
      // 表单对象
      content: [],
      // 任务列表
      tasks: [],
      // 七牛云cdn域名
      qiniu_cdn: ''
    }
  },
  methods: {
    // 提交表单
    async onSubmit () {
      // 提交表单
      this.content = this.content.map(item => {
        item.sort = parseInt(item.sort)
        return item
      })
      const { data: res } = await this.$http.post('/task/updateFloatingRedPacketTask', { content: this.content })
      if (res && res.code === 0) {
        this.$message.success('浮动红包任务更新成功')
      } else {
        this.$message.error('浮动红包任务更新失败:' + res.message)
      }
    },
    // 添加任务项
    addTaskItem (contentItemIndex) {
      this.content[contentItemIndex].task_list.push({
        icon: '',
        task_id: 0,
        task_type: 'coin',
        factor: 1,
        range_start: 0,
        range_end: 1,
        index: []
      })
    },
    // 删除任务条目
    deleteTaskItem (contentItemIndex, data) {
      // 删除对应索引的任务项
      this.content[contentItemIndex].task_list.splice(data, 1)
      // 删除对应索引的任务id
      this.content[contentItemIndex].task_id_arr.splice(data, 1)
    },
    // 更新任务条目
    updateTaskItem (contentItemIndex) {
      // 更新新的新的任务id数组 为 当前索引所有被选中的任务id
      this.content[contentItemIndex].task_id_arr = this.content[contentItemIndex].task_list.map(item => {
        return item.task_id
      })
      this.$forceUpdate()
    }
  },
  async created () {
    // 获取所有标签列表
    const { data: res } = await this.$http.get('/task/floatingRedPacketTask')
    if (res && res.code === 0) {
      this.content = res.data.info.content
      // 遍历content，所有条目添加 tasks 字段
      this.content = this.content.map(item => {
        item.task_id_arr = item.task_list.map(task => {
          return task.task_id
        })
        return item
      })
      // 七牛云cdn域名
      this.qiniu_cdn = res.data.qiniu_cdn
    }
    // 获取任务列表
    const { data: tasksRes } = await this.$http.get('/task/floatingRedPacketTaskList')
    if (tasksRes && tasksRes.code === 0) {
      this.tasks = tasksRes.data.list
    }
  },
  computed: {
    // 多个选择框不能选择同一个
  }
}
</script>

<style scoped>
/* 图标上传组件 */
.avatar-uploader .avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .avatar-uploader-icon:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

/*  tooltip组件 */
.tooltip_text {
  line-height: 20px
}
</style>

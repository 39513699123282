<template>
  <el-row :gutter="10">
    <el-button icon="el-icon-delete" type="danger" circle @click="deleteItem"></el-button>
    <el-col :span="14">
      <el-form ref="form" :model="form" size="mini"
               style="border: #4A5064 1px solid; background-color: #d9d9d9;padding: 10px;margin-bottom: 30px"
               label-width="80px">
        <el-form-item label="基本信息">
          广告ID：<el-input v-model="form.ad_id" style="width: 300px;margin-right: 10px"></el-input>
          广告标题：<el-input v-model="form.title" style="width: 300px;margin-right: 10px"></el-input>
        </el-form-item>
        <el-form-item label="广告类型">
          <template>
            <el-radio v-model="form.ad_type" :label="1">banner</el-radio>
            <el-radio v-model="form.ad_type" :label="2">激励视频</el-radio>
            <el-radio v-model="form.ad_type" :label="3">半屏图片插屏</el-radio>
            <el-radio v-model="form.ad_type" :label="4">全屏图片插屏</el-radio>
            <el-radio v-model="form.ad_type" :label="5">半屏视频插屏</el-radio>
            <el-radio v-model="form.ad_type" :label="6">全屏视频插屏</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="播放画面">
          宽度：<el-input v-model="form.config.image_ad_size.width" style="width: 100px;margin-right: 10px"></el-input>
          高度：<el-input v-model="form.config.image_ad_size.height" style="width: 100px;margin-right: 10px"></el-input>
          是否静音：<el-switch v-model="form.config.muted"></el-switch>
        </el-form-item>
        <el-form-item label="激励配置">
          用户id：<el-input v-model="form.config.user_id" style="width: 150px;margin-right: 10px"></el-input>
          奖励名称：<el-input v-model="form.config.reward_name" style="width: 150px;margin-right: 10px"></el-input>
          奖励数量：<el-input v-model="form.config.reward_amount" style="width: 150px;margin-right: 10px"></el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: 'Advertisement',
  props: {
    obj: {
      type: Object,
      default: () => {
        return {
          ad_id: '',
          title: '',
          ad_type: 1,
          config: {
            image_ad_size: {
              width: '',
              height: ''
            },
            muted: false,
            user_id: '',
            reward_name: '',
            reward_amount: ''
          }
        }
      }
    }
  },
  data () {
    return {
      form: {}
    }
  },
  watch: {
    obj: {
      handler (val) {
        // this.form = val
        this.$set(this, 'form', val)
        // 数据更新通知父组件
        this.$emit('update', val)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    onSubmit () {
      console.log('submit!')
    },
    deleteItem () {
      this.$emit('delete', this.obj)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <!--卡片视图区域-->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-select v-model="queryInfo.task_label_id" clearable placeholder="选择标签" @change="onLabelSelectorChange">
            <el-option
              v-for="item in labels"
              :key="item.task_label_id"
              :label="item.title"
              :value="item.task_label_id">
              <span style="float: left;">{{ item.title }}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <el-input placeholder="任务标识" v-model="queryInfo.identify" @keyup.enter.native="getList" clearable
                    @clear="getList">
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="gotoAddPage">添加任务</el-button>
        </el-col>
      </el-row>
      <!--任务列表区域-->
      <el-table :data="list" border stripe>
        <el-table-column prop="task_id" label="任务ID" width="100"></el-table-column>
        <el-table-column prop="after_task_id" label="后续任务ID" width="100"></el-table-column>
        <el-table-column prop="identify" label="任务标识" width="250"></el-table-column>
        <el-table-column label="任务名称" width="200">
          <template slot-scope="scope">
            <span style="color: #3967FF">{{ scope.row.task_name }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="task_label_name" label="所属标签" width="220" align="center"></el-table-column>
        <el-table-column label="状态" width="80" align="center">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_open_status" @change="changeIsOpenStatus(scope.row.task_id, scope.row.is_open_status)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column label="任务类型" width="100" align="center">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.task_type === 1">单次任务</el-tag>
            <el-tag type="success" v-if="scope.row.task_type === 2">多次任务</el-tag>
            <el-tag type="info" v-if="scope.row.task_type === 3">每日任务</el-tag>
            <el-tag type="warning" v-if="scope.row.task_type === 4">后置任务</el-tag>
            <el-tag type="danger" v-if="scope.row.task_type === 5">提现任务</el-tag>
            <el-tag color="#c5cae9" v-if="scope.row.task_type === 6">补充任务</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="金币变动方式" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.opt_type === 0" style="color: red">扣除</span>
            <span v-if="scope.row.opt_type === 1" style="color: deepskyblue">增加</span>
          </template>
        </el-table-column>
        <el-table-column label="是否系统任务" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="!scope.row.is_system">否</span>
            <span v-if="scope.row.is_system" style="color: red">是</span>
          </template>
        </el-table-column>
        <el-table-column label="图标" width="100" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.icon_url !== ''"
              style="width: 60px; cursor: pointer"
              :src="scope.row.icon_url"
              :preview-src-list="[scope.row.icon_url]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="task_desc" label="任务说明" width="180"></el-table-column>
        <el-table-column prop="sort" label="排序" width="90"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180"></el-table-column>
        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改任务" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="gotoEditPage(scope.row.task_id)">修改
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除任务" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteCategory(scope.row.task_id)" :disabled="scope.row.is_system">删除
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :key="queryInfo.page">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 标签列表
      labels: [],
      // 标签键值对
      labelMap: {},
      // 获取任务列表的参数
      queryInfo: {
        // 当前所属标签
        task_label_id: 0,
        // 任务标识
        identify: '',
        // 当前的页数
        page: 1,
        limit: 10
      },
      list: [],
      total: 0
    }
  },
  async created () {
    // 获取标签列表
    const { data: res } = await this.$http.get('/task/allLabels')
    if (res && res.code === 0) {
      this.labels = res.data
      // 构造标签键值对
      this.labelMap = res.data.reduce((pre, cur) => {
        pre[cur.task_label_id] = cur.title
        return pre
      }, {})
    }
    // 获取当前路由参数中的task_label_id
    const taskLabelId = parseInt(this.$route.query.task_label_id ?? 0)
    this.queryInfo.task_label_id = taskLabelId > 0 ? taskLabelId : ''
    // 获取当前路由参数中的page
    const page = parseInt(this.$route.query.page ?? 1)
    this.queryInfo.page = page > 0 ? page : 1
    // 获取当前路由参数中的limit
    const limit = parseInt(this.$route.query.limit ?? 10)
    this.queryInfo.limit = (limit > 0 && limit < 100) ? limit : 10
    await this.getList()
  },
  methods: {
    // 获取列表
    async getList () {
      const { data: res } = await this.$http.get('/task/list', { params: this.queryInfo })
      if (res && res.code === 0) {
        this.list = res.data.data.map(item => {
          // 任务标签名称
          item.task_label_name = item.task_label_id > 0 ? this.labelMap[item.task_label_id] : ''
          // 任务开放状态
          item.is_open_status = item.is_open === 1
          return item
        })
        this.total = res.data.total
      }
    },
    // 监听pageSize的改变
    handleSizeChange (newSize) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          limit: newSize
        }
      })
      // 更新查询参数
      this.queryInfo.limit = newSize
      this.getList()
    },
    // 监听页码值的改变
    handleCurrentChange (newPage) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
      // 更新查询参数
      this.queryInfo.page = newPage
      this.getList()
    },
    // 编辑
    async gotoEditPage (taskId) {
      await this.$router.push({ path: '/task/edit', query: { task_id: taskId } })
    },
    // 添加
    async gotoAddPage () {
      await this.$router.push({ path: '/task/add' })
    },
    // 删除
    deleteCategory (id) {
      this.$confirm('此操作将永久该条目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.delete('/task/delete?task_id=' + id)
        if (res && res.code === 0) {
          this.$message.success('删除成功!')
          await this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 监听标签选择框的改变
    onLabelSelectorChange ($taskLabelId) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          task_label_id: $taskLabelId
        }
      })
      // 更新查询参数
      this.queryInfo.task_label_id = $taskLabelId
      this.getList()
    },
    // 监听Switch开关状态的改变
    async changeIsOpenStatus (taskId, isOpenStatus) {
      // 改变用户
      const { data: res } = await this.$http.post('/task/changeIsOpenStatus',
        {
          task_id: taskId,
          is_open: isOpenStatus ? 1 : 0
        })
      if (res && res.code === 0) {
        this.$message.success('状态变更成功')
      } else {
        this.$message.success('状态变更失败:' + res.msg)
        this.getList().then(r => {
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>

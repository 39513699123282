<template>
  <el-container class="home-container">
    <!--头部-->
    <el-header>
      <div>
        <!--<img src="../assets/logo.png">-->
        <span>天天爱健康-后台管理系统</span>
      </div>
      <div>
        <el-button type="danger" @click="logout" size="medium" icon="el-icon-circle-close">退出</el-button>
      </div>
    </el-header>
    <!--主体-->
    <el-container>
      <!--侧边-->
      <el-aside :width="isCollapse ? '64px' : '200px'">
        <div class="toggle-button" @click="toggleCollapse">|||</div>
        <!--侧边栏菜单区域-->
        <el-menu
          :default-active="activePath"
          background-color="#333744"
          text-color="#fff"
          :unique-opened="true"
          :collapse="isCollapse"
          :collapse-transition="false"
          :router="true"
          active-text-color="#409EFF">
          <!--一级菜单-->
          <el-submenu index="1">
            <!--一级菜单模板区域-->
            <template slot="title">
              <!--图标-->
              <i class="iconfont icon-xitongguanli_yonghuguanli"></i>
              <!--文本-->
              <span>用户管理</span>
            </template>
            <!--二级菜单-->
            <el-menu-item index="/user/index" @click="saveNavState('/user/index')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-userlist"></i>
                <!--文本-->
                <span>用户列表</span>
              </template>
            </el-menu-item>
          </el-submenu>
          <!--一级菜单-->
          <el-submenu index="2">
            <!--一级菜单模板区域-->
            <template slot="title">
              <!--图标-->
              <i class="iconfont icon-xitongshezhi"></i>
              <!--文本-->
              <span>任务系统</span>
            </template>
            <!--二级菜单-->
            <el-menu-item index="/task/labels" @click="saveNavState('/task/labels')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-biaoqian"></i>
                <!--文本-->
                <span>标签管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/task/index" @click="saveNavState('/task/index')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-fuwurenwuguanli"></i>
                <!--文本-->
                <span>任务管理</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/task/float_red_packet" @click="saveNavState('/task/float_red_packet')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-hongbao"></i>
                <!--文本-->
                <span>浮动红包设置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/task/withdraw_amounts" @click="saveNavState('/task/withdraw_amounts')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-icon_xinyong_xianxing_jijin-259"></i>
                <!--文本-->
                <span>提现金额设置</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/task/web_games" @click="saveNavState('/task/web_games')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-H"></i>
                <!--文本-->
                <span>H5游戏设置</span>
              </template>
            </el-menu-item>
          </el-submenu>

          <!--一级菜单-->
          <el-submenu index="3">
            <!--一级菜单模板区域-->
            <template slot="title">
              <!--图标-->
              <i class="iconfont icon-shujuguanli"></i>
              <!--文本-->
              <span>数据报表</span>
            </template>
            <!--二级菜单-->
            <el-menu-item index="/statistics/trades" @click="saveNavState('/statistics/trades')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-jiaoyiliushui"></i>
                <!--文本-->
                <span>金币流水</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/task_logs" @click="saveNavState('/statistics/task_logs')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-rizhi"></i>
                <!--文本-->
                <span>任务日志</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/orders" @click="saveNavState('/statistics/orders')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-tixian"></i>
                <!--文本-->
                <span>提现订单</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/monthly_sign" @click="saveNavState('/statistics/monthly_sign')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-daka"></i>
                <!--文本-->
                <span>月度打卡记录</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/custom_tasks" @click="saveNavState('/statistics/custom_tasks')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-zidingyirenwu"></i>
                <!--文本-->
                <span>自定义任务</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/custom_sign" @click="saveNavState('/statistics/custom_sign')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-qiandaodaka"></i>
                <!--文本-->
                <span>自定义打卡</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/custom_note" @click="saveNavState('/statistics/custom_note')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-youliaobiji"></i>
                <!--文本-->
                <span>自定义笔记</span>
              </template>
            </el-menu-item>
            <el-menu-item index="/statistics/user_actions" @click="saveNavState('/statistics/user_actions')">
              <template slot="title">
                <!--图标-->
                <i class="iconfont icon-jianzhuanquangongchengicons-"></i>
                <!--文本-->
                <span>用户行为记录</span>
              </template>
            </el-menu-item>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <!--右侧-->
        <el-main class="route">
          <!--面包屑导航区域-->
          <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="(item, index) in breadcrumb" :key="index">{{ item }}</el-breadcrumb-item>
          </el-breadcrumb>
          <!--路由占位符-->
          <router-view style="margin-top: 30px"></router-view>
        </el-main>
        <!--底部-->
        <el-footer>天天爱健康 @{{ new Date().getFullYear() }}</el-footer>
      </el-container>
    </el-container>
  </el-container>
</template>

<script>
export default {
  name: 'Home',
  data () {
    return {
      // 是否折叠
      isCollapse: false,
      // 被激活的路由地址
      activePath: '',
      // 面包屑内容
      breadcrumb: []
    }
  },
  created () {
    // 从sessionStorage中获取上次活动的 路由地址，sessionStorage不存在，直接从路由中获取
    this.activePath = sessionStorage.getItem('activePath') || this.$route.path
    // 更新面包屑
    this.updateBreadcrumb()
  },
  updated () {
    // 页面更新，更新路由
    this.activePath = this.$route.path
    window.sessionStorage.setItem('activePath', this.activePath)
    // 更新面包屑
    this.updateBreadcrumb()
  },
  methods: {
    logout () {
      window.sessionStorage.clear()
      this.$router.push('/login')
    },
    // 切换菜单的折叠与展开
    toggleCollapse () {
      this.isCollapse = !this.isCollapse
    },
    // 保存链接的激活状态
    saveNavState (activePath) {
      window.sessionStorage.setItem('activePath', activePath)
      this.activePath = activePath
    },
    // 更新面包屑
    updateBreadcrumb () {
      const title = this.$route.meta.title
      const titleArr = title.split('-')
      if (titleArr.toString() !== this.breadcrumb.toString()) {
        this.breadcrumb = titleArr
      }
    }
  }
}
</script>

<style lang="less" scoped>
.route{
    height: calc(100vh - 120px) !important;
    overflow: auto;
    overflow-y: scroll;
}
.home-container {
  height:100vh
}

.el-header {
  background-color: #373D41;
  display: flex;
  justify-content: space-between; //让子控件左右贴边对齐
  align-items: center;
  color: white;
  font-size: 20px;

  > div {
    display: flex;
    align-items: center;

    span {
      margin-left: 15px;
    }
  }
}

.el-aside {
  background-color: #333744;

  .el-menu {
    border-right: none;
  }
}

.el-main {
  background-color: #FFFFFF;
}

.el-footer {
  background-color: #EEEEEE;
  display: flex;
  align-items: center;
}

.toggle-button {
  background-color: #4A5064;
  font-size: 10px;
  line-height: 24px;
  color: white;
  text-align: center;
  letter-spacing: 0.2em;
  cursor: pointer;
}

.header_drop {
  margin-right: 20px;

  .header_drop_title {
    color: white;
    font-style: normal;
    font-size: 26px;
    cursor: pointer;
  }
}
</style>

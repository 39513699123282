<template>
  <div class="not-found">
    <h1 class="not-found-title">404！页面找不到！</h1>
    <p class="not-found-desc">你似乎访问了一个不存在的页面，<a href="/">点击我</a> 回到首页</p>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style lang="less" scoped>
.not-found {
  text-align: center;
  padding: 100px 0;

  .not-found-title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #0077aa;
  }

  .not-found-desc {
    font-size: 16px;
    color: #666;
  }
}
</style>

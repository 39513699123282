import List from '../views/user/Index'

export default [
  // 用户列表
  {
    path: '/user/index',
    name: 'list',
    component: List,
    meta: {
      title: '用户管理-用户列表'
    }
  }
]

import Vue from 'vue'
import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'
// 设置请求的根路径
const protocol = window.location.protocol
axios.defaults.baseURL = `${protocol}//admin.corp.ttajiankang.com/api`
// 开启图片验证码的支持
axios.defaults.withCredentials = true

// 请求拦截器
axios.interceptors.request.use((config) => {
  // 设置请求头
  config.headers.Authorization = window.sessionStorage.getItem('access_token')
  return config
}, (error) => {
  return Promise.reject(error)
})

// 响应拦截器
axios.interceptors.response.use(data => {
  switch (data.data.code) {
    // 返回401，清除token信息并跳转到登录页面
    case 401:
      localStorage.removeItem('access_token')
      router.push('/login').then(r => {
      })
  }
  return data
}, error => {
  if (error && error.response) {
    Message.error(error.response.data.message)
    switch (error.response.status) {
      // 返回401状态码，清除token信息并跳转到登录页面，
      // 但目前此处不会返回401，所有接口返回 200
      case 401:
        localStorage.removeItem('access_token')
        router.push('/login').then(r => {
        })
    }
  } else {
    Message.error('连接服务器失败!')
  }
  return Promise.resolve(error)
})

// 将axios挂载为Vue的http属性
Vue.prototype.$http = axios

<template  style="overflow-y:auto; overflow-x:auto; height: calc(100vh - 120px)">
  <div>
    <el-card class="box-card">
      <el-form ref="form" :model="form" label-width="80px">
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="任务名称" label-width="100px">
              <el-input v-model="form.task_name"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="任务标识" label-width="100px">
              <el-input v-model="form.identify" :disabled="form.is_system"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="后置任务" label-width="100px" v-if="form.task_type !== 4">
          <el-select v-model="form.after_task_id" filterable placeholder="请选择后置任务">
            <el-option
              :key="0"
              label="无后置任务"
              :value="0">
            </el-option>
            <el-option
              v-for="item in afterTasks"
              :key="item.task_id"
              :label="item.task_name"
              :value="item.task_id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属标签" label-width="100px">
          <el-select v-model="form.task_label_id" placeholder="请选择所属标签" :disabled="form.is_system">
            <el-option
              v-for="item in labels"
              :key="item.task_label_id"
              :label="item.title"
              :value="item.task_label_id">
              <span style="float: left;">{{ item.title }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="任务描述" label-width="100px">
              <el-input type="textarea" v-model="form.task_desc" size="medium"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="备注" label-width="100px">
              <el-input type="textarea" v-model="form.remark" size="medium"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="12">
            <el-form-item label="图标" label-width="100px">
              <el-upload
                class="avatar-uploader"
                :action="this.cosUploadUrl"
                :data="this.cosParams"
                :show-file-list="false"
                :on-success="handleIconSuccess"
                :before-upload="beforeIconUpload">
                <img alt="图片不存在" v-if="form.icon" :src="this.iconUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="任务类型" label-width="100px">
          <template>
            <el-radio v-model="form.task_type" :disabled="form.is_system" :label="1">单次任务</el-radio>
            <el-radio v-model="form.task_type" :disabled="form.is_system" :label="2">多次任务</el-radio>
            <el-radio v-model="form.task_type" :disabled="form.is_system" :label="3">每日单次任务</el-radio>
            <el-radio v-model="form.task_type" :disabled="form.is_system" :label="4">后置任务</el-radio>
            <el-radio v-model="form.task_type" :disabled="form.is_system" :label="5">提现任务</el-radio>
            <el-radio v-model="form.task_type" :disabled="form.is_system" :label="6">补充任务</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="补充类型" label-width="100px" v-if="form.task_type === 6">
          <el-select v-model="form.addition_type" placeholder="请选择补充类型">
            <el-option label="连续打卡" value="continuous"></el-option>
            <el-option label="红包雨" value="red_packet_rain"></el-option>
            <el-option label="开宝箱" value="open_treasure_box"></el-option>
          </el-select>
        </el-form-item>
        <el-row :gutter="10" v-if="additionTaskLabelIds.includes(form.task_label_id)">
          <el-col :span="4">
            <el-form-item label="补充数值" label-width="100px">
              <el-input type="number" v-model="form.addition_value" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-tooltip placement="top">
              <div slot="content" class="tooltip_text">
                <span style="font-size: 13px;line-height: 2em">针对如下特定的任务生效：</span><br>
                - 连续的打卡任务：表示连续打卡的满足天数;<br>
                - 运动和习惯：表示执行任务所需要的时间(单位：分钟)</div>
              <el-button>查看说明</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-form-item label="金币变动方式" label-width="100px">
          <el-select v-model="form.opt_type" placeholder="请选择金币变动方式">
            <el-option label="增加" :value="1"></el-option>
            <el-option label="扣除" :value="0"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="奖励类型" label-width="100px" v-if="form.award_type !== 4">
          <template>
            <el-radio v-model="form.award_type" :label="1">固定</el-radio>
            <el-radio v-model="form.award_type" :label="2">随机</el-radio>
            <el-radio v-model="form.award_type" :label="3" v-if="form.task_type === 4">特定前置任务加倍</el-radio>
          </template>
        </el-form-item>
        <el-row :gutter="10" v-if="form.award_type === 1">
          <el-col :span="4">
            <el-form-item label="固定金币数" label-width="100px">
              <el-input type="number" v-model="form.coins" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="form.award_type === 2">
          <el-col :span="4">
            <el-form-item label="随机金币开始值" label-width="130px">
              <el-input type="number" v-model="form.coins_start_range" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="随机金币结束值" label-width="130px">
              <el-input type="number" v-model="form.coins_end_range" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="随机金币最高值" label-width="130px">
              <el-input type="number" v-model="form.coins_highest_range" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10" v-if="form.award_type === 3">
          <el-col :span="4">
            <el-form-item label="倍数" label-width="100px">
              <el-input type="number" v-model="form.coins_before_times" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="4">
            <el-form-item label="任务间隔(秒)" label-width="100px">
              <el-input type="number" v-model="form.interval" placeholder="请填写整型数值"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-tooltip placement="top">
              <div slot="content" class="tooltip_text">
                <span style="font-size: 13px;line-height: 2em">做任务时间间隔：</span><br>
                - 数值大于0，则表示该值是做任务的间隔时间(单位：秒)<br>
                - 数值等于0，则做任务不受时间间隔限制</div>
              <el-button>查看说明</el-button>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-form-item label="红包类型" label-width="100px">
          <el-col :span="4">
            <el-select v-model="form.red_packet_type" placeholder="请选择红包类型">
              <el-option label="无红包" :value="0"></el-option>
              <el-option label="普通红包" :value="1"></el-option>
              <el-option label="数字红包" :value="2"></el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-tooltip placement="top">
              <div slot="content" class="tooltip_text">
                <span style="font-size: 13px;line-height: 2em">用于控制客户端打开广告的前置样式：</span><br>
                - 无红包：直接打卡广告<br>
                - 普通红包：先弹出一个普通红包弹框，用户点击后再显示广告<br>
                - 数字红包：先弹出一个带红包金额的红包弹框，用户点击后再显示广告
                </div>
              <el-button>查看说明</el-button>
            </el-tooltip>
          </el-col>
        </el-form-item>
        <el-form-item label="吐司类型" label-width="100px">
          <el-col :span="4">
            <el-select v-model="form.toast_type" placeholder="请选择吐司类型">
              <el-option label="普通吐司" :value="1"></el-option>
              <el-option label="普通金币弹窗" :value="2"></el-option>
              <el-option label="翻倍金币弹窗" :value="3"></el-option>
              <el-option label="红包弹窗" :value="4"></el-option>
            </el-select>
          </el-col>
          <el-col :span="10">
            <el-tooltip placement="top">
              <div slot="content" class="tooltip_text">
                <span style="font-size: 13px;line-height: 2em">用于控制客户端广告播放结束的后置弹框：</span><br>
                - 普通吐司：弹出普通Toast<br>
                - 普通金币弹窗：弹出普通金币弹窗<br>
                - 翻倍金币弹窗：弹出翻倍金币弹窗，一般要配置选择一个后置任务，弹完之后打卡后置任务广告<br>
                - 红包弹窗：弹出红包弹窗
              </div>
              <el-button>查看说明</el-button>
            </el-tooltip>
          </el-col>
        </el-form-item>
        <el-form-item label="是系统任务" label-width="100px">
          <el-switch v-model="form.is_system" disabled></el-switch>
        </el-form-item>
        <el-form-item label="是否限制时间" label-width="100px">
          <el-switch v-model="form.is_time_limit"></el-switch>
        </el-form-item>
        <el-form-item label="限制时间区间" label-width="100px" v-if="form.is_time_limit">
          <el-time-picker
            is-range
            v-model="form.time_range"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            placeholder="选择时间范围">
          </el-time-picker>
        </el-form-item>
        <el-form-item label="Android广告" label-width="100px">
          <el-row style="margin: 5px">
            <el-button icon="el-icon-circle-plus" type="success" circle @click="addAndroidAdvertiseItem"></el-button>
          </el-row>
          <advertisement v-for="(item, index) in form.advertisements.android"
                         @update="updateAndroidAdvertisement"
                         :key="index"
                         :obj="item"
                         @delete="deleteAndroidAdvertiseItem"></advertisement>
        </el-form-item>
        <el-form-item label="IOS广告" label-width="100px">
          <el-row style="margin: 5px">
            <el-button icon="el-icon-circle-plus" type="success" circle @click="addIOSAdvertiseItem"></el-button>
          </el-row>
          <advertisement v-for="(item, index) in form.advertisements.ios"
                         @update="updateIOSAdvertisement"
                         :key="index"
                         :obj="item"
                         @delete="deleteIOSAdvertiseItem"></advertisement>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">保存</el-button>
          <el-button @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import Advertisement from '../../components/Advertisement'
export default {
  name: 'Editor',
  components: { Advertisement },
  data () {
    return {
      // 表单对象
      form: {
        task_name: '',
        identify: '',
        after_task_id: 0,
        task_label_id: 0,
        task_desc: '',
        remark: '',
        icon: '',
        task_type: 4,
        addition_type: null,
        addition_value: 0,
        opt_type: 1,
        award_type: 1,
        coins: 0,
        coins_start_range: 0,
        coins_end_range: 0,
        coins_highest_range: 0,
        coins_before_times: 1,
        interval: 0,
        red_packet_type: 0,
        toast_type: 1,
        is_system: false,
        is_time_limit: 0,
        time_range: '',
        advertisements: {
          android: [],
          ios: []
        },
        is_open: 0
      },
      // 图标链接
      iconUrl: '',
      // 文件上传链接
      cosUploadUrl: '',
      // cos文件上传参数
      cosParams: {},
      // 后置任务列表
      afterTasks: [],
      // 任务标签列表
      labels: [],
      // 需要补充数值的任务标签ID
      additionTaskLabelIds: [6, 7, 8]
    }
  },
  methods: {
    // 提交表单
    async onSubmit () {
      // 提交表单
      const { data: res } = await this.$http.post('/task/add', this.form)
      if (res && res.code === 0) {
        this.$message.success('任务添加成功')
        this.$router.go(-1)
      } else {
        this.$message.error('任务添加失败:' + res.message)
      }
    },
    // 图标上传成功回调
    handleIconSuccess (res, file) {
      this.iconUrl = URL.createObjectURL(file.raw)
      // 变更表单的图标
      this.form.icon = res.key
    },
    // 图标上传前回调
    async beforeIconUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      // 获取文件后缀名最为上传的文件类型
      const suffix = file.name.split('.').pop()
      // 获取cos上传凭证
      const { data: res } = await this.$http.get('/common/uploadToken?filetype=' + suffix)
      if (res && res.code === 0) {
        this.cosUploadUrl = `${res.data.upload_api}?token=${res.data.args.token}`
        // 更改文件上传key
        this.cosParams.key = res.data.args.key
      }

      return isLt2M
    },
    // 添加android广告条目
    addAndroidAdvertiseItem () {
      this.form.advertisements.android.push({
        ad_id: this.form.advertisements.android.length,
        title: '',
        type: 1,
        config: {
          image_ad_size: {
            width: '',
            height: ''
          },
          muted: false,
          user_id: '',
          reward_name: '',
          reward_amount: ''
        }
      })
    },
    // 删除android广告条目
    deleteAndroidAdvertiseItem (data) {
      this.form.advertisements.android.splice(this.form.advertisements.android.indexOf(data), 1)
    },
    // 更新android广告条目
    updateAndroidAdvertisement (newVal) {
      // 从子组件传递过来的数据
    },
    // 添加ios广告条目
    addIOSAdvertiseItem () {
      this.form.advertisements.ios.push({
        ad_id: this.form.advertisements.ios.length,
        title: '',
        ad_type: 1,
        config: {
          image_ad_size: {
            width: '',
            height: ''
          },
          muted: false,
          user_id: '',
          reward_name: '',
          reward_amount: ''
        }
      })
    },
    // 删除ios广告条目
    deleteIOSAdvertiseItem (data) {
      this.form.advertisements.ios.splice(this.form.advertisements.ios.indexOf(data), 1)
    },
    // 更新ios广告条目
    updateIOSAdvertisement (data) {
    },
    // 取消
    cancel () {
      this.$router.go(-1)
    }
  },
  async created () {
    // 获取所有标签列表
    const { data: res } = await this.$http.get('/task/allLabels')
    if (res && res.code === 0) {
      this.labels = res.data
    }
    // 获取所有后置任务列表
    const { data: tasks } = await this.$http.get('/task/afterTasks')
    if (tasks && tasks.code === 0) {
      this.afterTasks = tasks.data
    }
  }
}
</script>

<style scoped>
/* 图标上传组件 */
.avatar-uploader .avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .avatar-uploader-icon:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

/*  tooltip组件 */
.tooltip_text {
  line-height: 20px
}
</style>

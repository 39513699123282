<template>
  <div>
    <!--卡片视图区域-->
    <el-card class="box-card">
      <!--标签列表区域-->
      <el-table :data="list" border stripe>
        <el-table-column prop="task_label_id" label="标签ID" width="90"></el-table-column>
        <el-table-column prop="identify" label="标识" width="230"></el-table-column>
        <el-table-column prop="title" label="标签标题"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180"></el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180"></el-table-column>
        <el-table-column label="操作" width="180" align="center">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="查看所属标签任务列表" placement="top" :enterable="false">
              <el-button type="success" icon="el-icon-view" size="mini" @click="gotoTaskIndex(scope.row.task_label_id)">查看
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除标签" placement="top" :enterable="false">
              <el-button disabled type="danger" icon="el-icon-delete" size="mini" @click="deleteTaskLabel(scope.row.id)">删除
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :key="queryInfo.page"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
export default {
  data () {
    return {
      // 获取标签列表的参数
      queryInfo: {
        // 当前的页数
        page: 1,
        limit: 10
      },
      list: [],
      total: 0
    }
  },
  created () {
    // 获取当前路由参数中的page
    const page = parseInt(this.$route.query.page ?? 1)
    this.queryInfo.page = page > 0 ? page : 1
    // 获取当前路由参数中的limit
    const limit = parseInt(this.$route.query.limit ?? 10)
    this.queryInfo.limit = (limit > 0 && limit < 100) ? limit : 10
    this.getList()
  },
  methods: {
    async getList () {
      const { data: res } = await this.$http.get('/task/labels', { params: this.queryInfo })
      if (res && res.code === 0) {
        this.list = res.data.data.map(item => {
          if (item.lock === 0) {
            item.status = true
          } else {
            item.status = false
          }
          return item
        })
        this.total = res.data.total
      }
    },
    // 监听pageSize的改变
    handleSizeChange (newSize) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          limit: newSize
        }
      })
      // 更新查询参数
      this.queryInfo.limit = newSize
      this.getList()
    },
    // 监听页码值的改变
    handleCurrentChange (newPage) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
      // 更新查询参数
      this.queryInfo.page = newPage
      this.getList()
    },
    // 跳转到任务列表
    gotoTaskIndex (id) {
      this.$router.push({
        path: '/task/index',
        query: {
          task_label_id: id
        }
      })
    },
    // 删除任务标签
    deleteTaskLabel (id) {
      this.$confirm('此操作将永久该条目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        // TODO 删除功能暂时没有开发，计划后续接入 ACL 之后，只允许开发人员进行开发
        const { data: res } = await this.$http.post('/task/labels/delete')
        if (res && res.code === 0) {
          this.$message.success('删除成功!')
          await this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>

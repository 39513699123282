<template>
  <div>
    <!--卡片视图区域-->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="20">
        <el-col :span="4">
          <el-button type="primary" @click="addDialogVisible=true">添  加</el-button>
        </el-col>
      </el-row>
      <!--用户列表区域-->
      <el-table :data="list" border stripe>
        <el-table-column prop="id" label="金额ID" width="150" align="center" fixed="left"></el-table-column>
        <el-table-column prop="amount" label="金额" width="150" align="center" fixed="left"></el-table-column>
        <el-table-column prop="reduce_coins" label="所需金币数" width="150" align="center"></el-table-column>
        <el-table-column prop="letter" label="前台文案" align="center"></el-table-column>
        <el-table-column label="权益类型" width="120" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.right_type === 0" style="color: darkorange">通用</span>
            <span v-if="scope.row.right_type === 1" style="color: deepskyblue">新人专享</span>
          </template>
        </el-table-column>
        <el-table-column label="是否开启" align="center" width="120">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.is_open_status"
              @change="handleSwitchChange(scope.row.id)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="sort" label="排序" align="center" width="120"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180" align="center"></el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180" align="center"></el-table-column>
        <el-table-column label="操作" width="180" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改提现金额" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="editWithdrawAmount(scope.row)">修改
              </el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除提现金额" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteWithdrawAmount(scope.row.id)">删除
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :key="queryInfo.page"
        :total="total">
      </el-pagination>
    </el-card>
    <!--添加的对话框-->
    <el-dialog
      title="添加提现金额"
      :visible.sync="addDialogVisible"
      @close="addDialogClose"
      :close-on-click-modal="false"
      width="30%">
      <el-form ref="addFormRef" :model="addForm" :rules="withdrawAmountFormRules" label-width="70px">
        <el-form-item label="提现金额" prop="amount" label-width="100">
          <el-input v-model="addForm.amount" type="number" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="所需金币数" prop="reduce_coins" label-width="100">
          <el-input v-model="addForm.reduce_coins" type="number" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="权限类型" prop="mobile" label-width="100">
          <el-radio-group v-model="addForm.right_type">
            <el-radio :label="0">所有用户</el-radio>
            <el-radio :label="1">新人专享</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="前台文案" label-width="100">
          <el-input v-model="addForm.letter" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="100">
          <el-input v-model="addForm.sort" type="number" value="1000" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="是否开启" prop="is_open_status" label-width="100">
          <el-switch v-model="addForm.is_open_status"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="addDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="addWithdrawAmount">确 定</el-button>
  </span>
    </el-dialog>
    <!--编辑的对话框-->
    <el-dialog
      title="更新提现金额"
      :visible.sync="editDialogVisible"
      @close="editDialogClose"
      :close-on-click-modal="false"
      width="30%">
      <el-form ref="editFormRef" :model="editForm" :rules="withdrawAmountFormRules" label-width="70px">
        <el-form-item label="提现金额" prop="amount" label-width="100">
          <el-input v-model="editForm.amount" type="number" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="所需金币数" prop="reduce_coins" label-width="100">
          <el-input v-model="editForm.reduce_coins" type="number" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="权限类型" prop="mobile" label-width="100">
          <el-radio-group v-model="editForm.right_type">
            <el-radio :label="0">所有用户</el-radio>
            <el-radio :label="1">新人专享</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="前台文案" label-width="100">
          <el-input v-model="editForm.letter" style="width: 80%"></el-input>
        </el-form-item>
        <el-form-item label="排序" prop="sort" label-width="100">
          <el-input v-model="editForm.sort" type="number" value="1000" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="是否开启" prop="is_open_status" label-width="100">
          <el-switch v-model="editForm.is_open_status"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="updateWithdrawAmount">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>

// 导入组件
export default {
  data () {
    return {
      // 获取用户列表的参数
      queryInfo: {
        // 当前的页数
        page: 1,
        limit: 10
      },
      list: [],
      total: 0,
      // 添加表单数据
      addForm: {
        amount: 1,
        reduce_coins: 1,
        right_type: 0,
        letter: '',
        sort: 1000,
        is_open_status: true
      },
      // 控制添加表单是否显示
      addDialogVisible: false,
      // 添加的对话框规则
      withdrawAmountFormRules: {
        amount: [
          {
            required: true,
            message: '提现金额为必填项',
            trigger: 'blur'
          }
        ],
        reduce_coins: [
          {
            required: true,
            message: '扣除金币数为必填项',
            trigger: 'blur'
          }
        ],
        right_type: [
          {
            required: true,
            message: '权限类型为必填项',
            trigger: 'blur'
          }
        ],
        letter: [
          {
            min: 1,
            max: 16,
            message: '文案的长度在2到16个字符之间',
            trigger: 'blur'
          }
        ],
        is_open_status: [
          {
            required: true,
            message: '是否开启为必传项',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '排序为必传项',
            trigger: 'blur'
          }
        ]
      },
      // 编辑表单数据
      editForm: {},
      // 控制编辑表单是否显示
      editDialogVisible: false,
      // 编辑的对话框规则
      editFormRules: {}
    }
  },
  created () {
    // 获取当前路由参数中的page
    const page = parseInt(this.$route.query.page ?? 1)
    this.queryInfo.page = page > 0 ? page : 1
    // 获取当前路由参数中的limit
    const limit = parseInt(this.$route.query.limit ?? 10)
    this.queryInfo.limit = (limit > 0 && limit < 100) ? limit : 10
    // 获取当前路由参数中的start_time
    this.queryInfo.start_time = decodeURIComponent(this.$route.query.start_time ?? '')
    // 获取当前路由参数中的end_time
    this.queryInfo.end_time = decodeURIComponent(this.$route.query.end_time ?? '')
    // 将时间回显到时间选择器
    this.pickerVal = [this.queryInfo.start_time, this.queryInfo.end_time]
    // 获取当前路由参数中的table_suffix
    this.queryInfo.table_suffix = this.$route.query.table_suffix ?? '0'
    this.getList()
  },
  methods: {
    async getList () {
      const { data: res } = await this.$http.get('/task/withdrawAmounts', { params: this.queryInfo })
      if (res && res.code === 0) {
        this.list = res.data.data
        this.total = res.data.total
      }
    },
    // 监听pageSize的改变
    handleSizeChange (newSize) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          limit: newSize
        }
      })
      // 更新查询参数
      this.queryInfo.limit = newSize
      this.getList()
    },
    // 监听页码值的改变
    handleCurrentChange (newPage) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
      // 更新查询参数
      this.queryInfo.page = newPage
      this.getList()
    },
    // 监听时间选择器变化
    onPickerChange (val) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          start_time: val[0],
          end_time: val[1]
        }
      })
      // 更新查询参数
      this.queryInfo.start_time = val[0]
      this.queryInfo.end_time = val[1]
      this.getList()
    },
    // 监听状态开关变化
    handleSwitchChange (id) {
      console.log('变化的开关', id)
    },
    // 添加对话框被关闭
    addDialogClose () {
      console.log('添加对话框被关闭')
    },
    // 删除
    deleteWithdrawAmount (id) {
      this.$confirm('此操作将永久该条目, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { data: res } = await this.$http.delete('/task/deleteWithdrawAmount?id=' + id)
        if (res && res.code === 0) {
          this.$message.success('删除成功!')
          await this.getList()
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 编辑对话框被关闭
    editDialogClose () {
      console.log('编辑对话框被关闭')
    },
    // 添加提现金额
    addWithdrawAmount () {
      this.$refs.addFormRef.validate(async valid => {
        if (!valid) {
          return
        }
        // 发起添加的网络请求
        if (this.addForm.is_open_status) {
          this.addForm.is_open = 1
        } else {
          this.addForm.is_open = 0
        }
        const { data: res } = await this.$http.post('/task/addWithdrawAmount', this.addForm)
        if (res && res.code === 0) {
          this.$message.success('添加成功')
          // 隐藏添加分类的对话框
          this.addDialogVisible = false
          await this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    // 编辑提现金额
    editWithdrawAmount (info) {
      this.editDialogVisible = true
      this.editForm = info
    },
    // 更新提现金额
    updateWithdrawAmount () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return
        }
        // 发起添加的网络请求
        if (this.editForm.is_open_status) {
          this.editForm.is_open = 1
        } else {
          this.editForm.is_open = 0
        }
        this.editForm.amount = parseFloat(this.editForm.amount)
        const { data: res } = await this.$http.post('/task/updateWithdrawAmount', this.editForm)
        if (res && res.code === 0) {
          this.$message.success('修改成功')
          // 隐藏添加分类的对话框
          this.editDialogVisible = false
          await this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>

</style>

import { render, staticRenderFns } from "./WithdrawAmounts.vue?vue&type=template&id=7285f8cf&scoped=true&"
import script from "./WithdrawAmounts.vue?vue&type=script&lang=js&"
export * from "./WithdrawAmounts.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7285f8cf",
  null
  
)

export default component.exports
import Login from '@/views/auth/Login'

export default [
  // 登录
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: '天天爱健康-登录'
    }
  }
]

import { render, staticRenderFns } from "./AddTask.vue?vue&type=template&id=c99b98e2&scoped=true&style=overflow-y%3Aauto%3B%20overflow-x%3Aauto%3B%20height%3A%20calc(100vh%20-%20120px)&"
import script from "./AddTask.vue?vue&type=script&lang=js&"
export * from "./AddTask.vue?vue&type=script&lang=js&"
import style0 from "./AddTask.vue?vue&type=style&index=0&id=c99b98e2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c99b98e2",
  null
  
)

export default component.exports
<template>
  <div>
    <!--卡片视图区域-->
    <el-card class="box-card">
      <!--搜索与添加区域-->
      <el-row :gutter="20">
        <el-col :span="4">
          <div>
            <el-radio-group v-model="queryInfo.type">
              <el-radio-button label="table">分表查询</el-radio-button>
              <el-radio-button label="uid">指定用户</el-radio-button>
            </el-radio-group>
          </div>
        </el-col>
        <el-col :span="4" v-if="queryInfo.type === 'table'">
          <el-select v-model="queryInfo.table_suffix" placeholder="选择分表" @change="onTablesSelectorChange">
            <el-option
              v-for="item in tables"
              :key="item.suffix"
              :label="item.name"
              :selected="item.selected"
              :value="item.suffix">
              <span style="float: left;">{{ item.name }}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="4" v-if="queryInfo.type === 'uid'">
          <el-input placeholder="UID精确查找" v-model="queryInfo.uid" @keyup.enter.native="getList" clearable
                    @clear="getList">
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
        <el-col :span="4">
          <el-date-picker
            v-model="pickerVal"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            @change="onPickerChange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right">
          </el-date-picker>
        </el-col>
      </el-row>
      <!--用户列表区域-->
      <el-table :data="list" border stripe>
        <el-table-column prop="id" label="日志ID" width="160" align="center" fixed="left"></el-table-column>
        <el-table-column prop="uid" label="用户ID" width="200" align="center" fixed="left"></el-table-column>
        <el-table-column prop="task_id" label="任务ID" width="160" align="center"></el-table-column>
        <el-table-column prop="latest_time" label="最后任务时间" align="center"></el-table-column>
        <el-table-column prop="total_times" label="总任务次数" width="160" align="center"></el-table-column>
        <el-table-column prop="total_coins" label="总获得金币数" width="160" align="center"></el-table-column>
        <el-table-column prop="total_reduce_coins" label="总扣除金币数" width="200" align="center"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="200" align="center"></el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="200" align="center"></el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :key="queryInfo.page"
        :total="total">
      </el-pagination>
    </el-card>
  </div>
</template>

<script>

// 导入组件
export default {
  data () {
    return {
      // 获取用户列表的参数
      queryInfo: {
        // 查询方式：table-分表查询，uid-指定用户
        type: 'table',
        // 指定分表后缀
        table_suffix: '0',
        // 指定用户的uid
        uid: '',
        // 开始时间与结束时间
        start_time: '',
        end_time: '',
        // 当前的页数
        page: 1,
        limit: 10
      },
      list: [],
      total: 0,
      // 分表信息
      tables: [
        {
          name: '表序号-0',
          suffix: '0',
          selected: true
        },
        {
          name: '表序号-1',
          suffix: '1'
        },
        {
          name: '表序号-2',
          suffix: '2'
        },
        {
          name: '表序号-3',
          suffix: '3'
        },
        {
          name: '表序号-4',
          suffix: '4'
        },
        {
          name: '表序号-5',
          suffix: '5'
        },
        {
          name: '表序号-6',
          suffix: '6'
        },
        {
          name: '表序号-7',
          suffix: '7'
        },
        {
          name: '表序号-8',
          suffix: '8'
        },
        {
          name: '表序号-9',
          suffix: '9'
        }
      ],
      // 时间选择器的配置
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      // 时间选择器值
      pickerVal: ''
    }
  },
  created () {
    // 获取当前路由参数中的page
    const page = parseInt(this.$route.query.page ?? 1)
    this.queryInfo.page = page > 0 ? page : 1
    // 获取当前路由参数中的limit
    const limit = parseInt(this.$route.query.limit ?? 10)
    this.queryInfo.limit = (limit > 0 && limit < 100) ? limit : 10
    // 获取当前路由参数中的type
    this.queryInfo.type = this.$route.query.type ?? 'table'
    // 获取当前路由参数中的start_time
    this.queryInfo.start_time = decodeURIComponent(this.$route.query.start_time ?? '')
    // 获取当前路由参数中的end_time
    this.queryInfo.end_time = decodeURIComponent(this.$route.query.end_time ?? '')
    // 将时间回显到时间选择器
    this.pickerVal = [this.queryInfo.start_time, this.queryInfo.end_time]
    // 获取当前路由参数中的table_suffix
    this.queryInfo.table_suffix = this.$route.query.table_suffix ?? '0'
    this.getList()
  },
  methods: {
    async getList () {
      const { data: res } = await this.$http.get('/statistics/userTaskLogs', { params: this.queryInfo })
      if (res && res.code === 0) {
        this.list = res.data.data
        this.total = res.data.total
      }
    },
    // 监听pageSize的改变
    handleSizeChange (newSize) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          limit: newSize
        }
      })
      // 更新查询参数
      this.queryInfo.limit = newSize
      this.getList()
    },
    // 监听页码值的改变
    handleCurrentChange (newPage) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
      // 更新查询参数
      this.queryInfo.page = newPage
      this.getList()
    },
    // 监听分表选择器变化
    onTablesSelectorChange (val) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          table_suffix: val
        }
      })
      // 更新查询参数
      this.queryInfo.table_suffix = val
      this.getList()
    },
    // 监听时间选择器变化
    onPickerChange (val) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          start_time: val[0],
          end_time: val[1]
        }
      })
      // 更新查询参数
      this.queryInfo.start_time = val[0]
      this.queryInfo.end_time = val[1]
      this.getList()
    }
  }
}
</script>

<style lang="less" scoped>

</style>

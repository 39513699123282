import Trades from '../views/statistics/Trades'
import TaskLogs from '../views/statistics/TaskLogs'
import MonthlySign from '../views/statistics/MonthlySign'
import CustomTasks from '../views/statistics/CustomTasks'
import CustomSign from '../views/statistics/CustomSign'
import CustomNote from '../views/statistics/CustomNote'
import Orders from '../views/statistics/Orders'
import UserActions from '../views/statistics/UserActions'

export default [
  // 金币流水
  {
    path: '/statistics/trades',
    name: 'statistics_trades',
    component: Trades,
    meta: {
      title: '数据报表-金币流水'
    }
  },
  // 任务日志
  {
    path: '/statistics/task_logs',
    name: 'statistics_task_logs',
    component: TaskLogs,
    meta: {
      title: '数据报表-任务日志'
    }
  },
  // 提现订单
  {
    path: '/statistics/orders',
    name: 'statistics_orders',
    component: Orders,
    meta: {
      title: '数据报表-提现订单'
    }
  },
  // 月度打卡记录
  {
    path: '/statistics/monthly_sign',
    name: 'statistics_monthly_sign',
    component: MonthlySign,
    meta: {
      title: '数据报表-月度打卡记录'
    }
  },
  // 自定义任务
  {
    path: '/statistics/custom_tasks',
    name: 'statistics_custom_tasks',
    component: CustomTasks,
    meta: {
      title: '数据报表-自定义任务'
    }
  },
  // 自定义打卡
  {
    path: '/statistics/custom_sign',
    name: 'statistics_custom_sign',
    component: CustomSign,
    meta: {
      title: '数据报表-自定义打卡'
    }
  },
  // 自定义笔记
  {
    path: '/statistics/custom_note',
    name: 'statistics_custom_note',
    component: CustomNote,
    meta: {
      title: '数据报表-自定义笔记'
    }
  },
  // 用户行为记录
  {
    path: '/statistics/user_actions',
    name: 'statistics_user_actions',
    component: UserActions,
    meta: {
      title: '数据报表-用户行为记录'
    }
  }
]

<template>
  <div class="login_container">
    <div class="login_box">
      <div class="box_header">
        <h1>天天爱健康-后台管理系统</h1>
      </div>
      <div class="box_content" style="text-align: center;">
            <el-button type="primary" style="width: 80%;" @click="login">登录</el-button>
      </div>
    </div>
    <div class="footer">
      <p class="footer-content">天天爱健康  盛勤@{{ new Date().getFullYear() }}</p>
    </div>
  </div>
</template>

<script>
import router from '@/router'

export default {
  name: 'Login',
  mounted () {
    window.sessionStorage.setItem('activePath', '/welcome') // 保存token
    // token 存在，进入首页
    if (this.$route.query.token) {
      window.sessionStorage.setItem('access_token', this.$route.query.token)
      router.push('/welcome').then(r => {})
    }
  },
  data () {
    return {
    }
  },
  methods: {
    login () {
      window.location.href = 'http://acl.mama.cn/'
    }
  }
}
</script>

<style lang="less" scoped>
.login_container {
  background: url(../../assets/img/bg.jpg) no-repeat;
  background-size: cover;
  height: 100%;
}

.login_box {
  width: 450px;
  position: absolute;
  left: 50%;
  top: 45%;
  transform: translate(-50%, -50%);

  .box_header {
    h1 {
      color: white;
      text-align: center;
    }
  }
}

.footer {
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  text-align: center;
  margin-bottom: 30px;

  .footer-content{
    color: white;
  }
}

</style>

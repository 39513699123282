import Index from '../views/task/Index'
import Labels from '../views/task/Labels'
import TaskEditor from '../views/task/TaskEditor'
import WithdrawAmounts from '../views/task/WithdrawAmounts'
import FloatRedPacket from '../views/task/FloatRedPacket'
import WebGames from '../views/task/WebGames'
import AddTask from '../views/task/AddTask'

export default [
  // 标签列表
  {
    path: '/task/labels',
    name: 'task_labels',
    component: Labels,
    meta: {
      title: '任务管理-标签列表'
    }
  },
  // 任务列表
  {
    path: '/task/index',
    name: 'task_index',
    component: Index,
    meta: {
      title: '任务管理-任务管理'
    }
  },
  // 添加任务
  {
    path: '/task/add',
    name: 'task_add',
    component: AddTask,
    meta: {
      title: '任务管理-添加任务'
    }
  },
  // 任务编辑
  {
    path: '/task/edit',
    name: 'task_edit',
    component: TaskEditor,
    meta: {
      title: '任务管理-任务编辑'
    }
  },
  // 浮动红包任务设置
  {
    path: '/task/float_red_packet',
    name: 'task_float_red_packet',
    component: FloatRedPacket,
    meta: {
      title: '任务管理-浮动红包任务设置'
    }
  },
  // 提现金额设置
  {
    path: '/task/withdraw_amounts',
    name: 'task_withdraw_amounts',
    component: WithdrawAmounts,
    meta: {
      title: '任务管理-提现金额设置'
    }
  },
  // 网页游戏设置
  {
    path: '/task/web_games',
    name: 'task_web_games',
    component: WebGames,
    meta: {
      title: '任务管理-网页游戏设置'
    }
  }
]

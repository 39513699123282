<template>
  <div>
    <!--卡片视图区域-->
    <el-card>
      <h3>欢迎使用：天天爱健康-后台管理系统！</h3>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'Welcome',
  mounted () {
    window.sessionStorage.setItem('activePath', '/welcome')
  }
}
</script>

<style scoped>
</style>

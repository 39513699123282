import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import auth from '../router/auth'
import Welcome from '../views/Welcome'
import user from '../router/user'
import task from '../router/task'
import statistics from './statistics'
import NotFound from '../views/NotFound'

Vue.use(VueRouter)

const routes = [
  // 首页
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect: '/welcome',
    meta: {
      title: '天天爱健康-首页'
    },
    children: [
      // 欢迎页
      {
        path: '/welcome',
        component: Welcome,
        meta: {
          title: '天天爱健康-欢迎使用'
        }
      },
      ...user, ...task, ...statistics
    ]
  },
  // 授权
  ...auth,
  // 不匹配路由，重定向到 NotFound
  {
    path: '*',
    component: NotFound,
    meta: {
      title: '页面找不到'
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 设置路由导航守卫
// to: 将要访问的路径, from: 从那个路径跳转而来, next: 放行函数,带参数则标识强制跳转的路径
// 设置路由导航守卫
// to: 将要访问的路径, from: 从那个路径跳转而来, next: 放行函数,带参数则标识强制跳转的路径
router.beforeEach((to, from, next) => {
  if (to.meta && to.meta.title) {
    document.title = to.meta.title
  }
  // 获取token
  const tokenStr = window.sessionStorage.getItem('access_token')
  // 登录页,存在token则跳转到首页,不存在直接放行
  if (to.path === '/login') {
    if (tokenStr) {
      return next('/')
    }
    return next()
  }

  // 找回密码界面和重置密码界面，直接放心
  if (to.path === '/find_password' || to.path === '/reset_password') {
    return next()
  }

  // 其他界面,token不存在,强制跳转到登录页
  if (!tokenStr) {
    // 测试环境可以注释以下这行代码
    return next('/login')
  }

  // token存在,直接放行
  next()
})

export default router

<template>
  <div style="padding: 20px; border: #4A5064 dashed 1px">
    <el-row :gutter="10">
      <el-button icon="el-icon-delete" type="danger" circle @click="deleteItem"></el-button>
      <el-form-item label="任务ID">
        <el-tag> {{form.task_id}} </el-tag>
      </el-form-item>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="图标">
            <el-upload
              class="avatar-uploader"
              :action="this.cosUploadUrl"
              :data="this.cosParams"
              :show-file-list="false"
              :on-success="handleIconSuccess"
              :before-upload="beforeIconUpload">
              <img alt="图片不存在" v-if="form.icon" :src="this.iconUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-select v-model="form.task_id" placeholder="所属任务" @change="onSelectChange">
            <el-option
              v-for="item in tasks"
              :key="item.task_id"
              :label="item.task_name"
              :disabled="disableItems.indexOf(item.task_id) !== -1"
              :value="item.task_id">
              <span style="float: left;">{{ item.task_name }}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-form-item label="任务类型">
            <template>
              <el-radio v-model="form.task_type" label="coin">金币</el-radio>
              <el-radio v-model="form.task_type" label="red_packet">红包</el-radio>
            </template>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="5">
          <el-form-item label="计算基数">
            <el-input type="number" v-model="form.factor" placeholder="请填写数值"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="计算随机开始值">
            <el-input type="number" v-model="form.range_start" placeholder="请填写整型数值"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="计算随机结束值">
            <el-input type="number" v-model="form.range_end" placeholder="请填写整型数值"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="索引位置">
            <el-input type="text" v-model="inputValue" placeholder="填写索引位置，多个用半角逗号隔开" @change="onIndexInputChange"></el-input>
            <div class="tag-group">
              <span class="tag-group__title">索引：</span>
              <el-tag
                style="margin-right: 10px"
                v-for="(item, index) in form.index"
                :key="index"
                effect="dark">
                {{ item }}
              </el-tag>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    // 数据对象
    obj: {
      type: Object,
      default: () => {
        return {
          icon: '',
          task_id: 0,
          task_type: 'coin',
          factor: 1,
          range_start: 0,
          range_end: 1,
          index: []
        }
      }
    },
    // 任务列表
    tasks: {
      type: Array,
      default: () => {
        return []
      }
    },
    contentIndex: Number,
    // 不可以选择的条目
    disableItems: {
      type: Array,
      default: () => {
        return []
      }
    },
    // 图标链接
    iconUrl: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data () {
    return {
      form: {},
      // 文件上传链接
      cosUploadUrl: '',
      // cos文件上传参数
      cosParams: {},
      // 输入内容
      inputValue: ''
    }
  },
  watch: {
    obj: {
      handler (newVal) {
        this.$set(this, 'form', newVal)
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    // 图标上传前回调
    async beforeIconUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      // 获取文件后缀名最为上传的文件类型
      const suffix = file.name.split('.').pop()
      // 获取cos上传凭证
      const { data: res } = await this.$http.get('/common/uploadToken?filetype=' + suffix)
      if (res && res.code === 0) {
        this.cosUploadUrl = `${res.data.upload_api}?token=${res.data.args.token}`
        // 更改文件上传key
        this.cosParams.key = res.data.args.key
      }

      return isLt2M
    },
    // 图标上传成功回调
    handleIconSuccess (res, file) {
      this.iconUrl = URL.createObjectURL(file.raw)
      // 变更表单的图标
      this.form.icon = res.key
    },
    // 删除条目
    deleteItem () {
      this.$emit('delete')
    },
    // 监听输入框变化
    onIndexInputChange () {
      this.form.index = this.inputValue.split(',')
    },
    // 监听下拉框变化
    onSelectChange (val) {
      // 判断是否存在相同的值，存在则不添加
      this.$emit('update')
    }
  }
}
</script>

<style scoped>
/* 图标上传组件 */
.avatar-uploader .avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .avatar-uploader-icon:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

/*  tooltip组件 */
.tooltip_text {
  line-height: 20px
}
</style>

<template>
  <div>
    <!--卡片视图区域-->
    <el-card class="box-card">
      <!--用户列表区域-->
      <el-table :data="list" border stripe>
        <el-table-column prop="game_id" label="游戏ID" width="150" align="center" fixed="left"></el-table-column>
        <el-table-column prop="identify" label="游戏标识" width="150" align="center" fixed="left"></el-table-column>
        <el-table-column prop="game_name" label="游戏名称" width="150" align="center"></el-table-column>
        <el-table-column label="游戏图标" width="130" align="center">
          <template slot-scope="scope">
            <el-image
              v-if="scope.row.icon_url !== ''"
              style="height: 60px;cursor: pointer"
              :src="scope.row.icon_url"
              :preview-src-list="[scope.row.icon_url]">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column prop="desc" label="游戏描述" align="center"></el-table-column>
        <el-table-column label="是否开启" align="center" width="120">
          <template slot-scope="scope">
            <el-switch
              disabled
              v-model="scope.row.is_open_status"
              @change="handleSwitchChange(scope.row.id)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="btn_text" label="按钮文字" align="center" width="120"></el-table-column>
        <el-table-column prop="btn_color" label="按钮色值" align="center" width="120"></el-table-column>
        <el-table-column prop="umeng_action" label="友盟动作点位" align="center" width="120"></el-table-column>
        <el-table-column prop="background_color1" label="背景色值1" align="center" width="120"></el-table-column>
        <el-table-column prop="background_color2" label="背景色值2" align="center" width="120"></el-table-column>
        <el-table-column prop="sort" label="排序" align="center" width="120"></el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="180" align="center"></el-table-column>
        <el-table-column prop="update_time" label="更新时间" width="180" align="center"></el-table-column>
        <el-table-column label="操作" width="130" align="center" fixed="right">
          <template slot-scope="scope">
            <el-tooltip effect="dark" content="修改提现金额" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="editWebGame(scope.row)">修改
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <!--分页区域-->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.page"
        :page-sizes="[5, 10, 20, 30, 50]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :key="queryInfo.page"
        :total="total">
      </el-pagination>
    </el-card>
    <!--编辑的对话框-->
    <el-dialog
      title="更新H5游戏"
      :visible.sync="editDialogVisible"
      @close="editDialogClose"
      :close-on-click-modal="false"
      width="40%">
      <el-form ref="editFormRef" :model="editForm" label-width="100px">
        <el-form-item label="游戏名称">
          <el-input v-model="editForm.game_name" type="text" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-upload
            class="avatar-uploader"
            :action="this.cosUploadUrl"
            :data="this.cosParams"
            :show-file-list="false"
            :on-success="handleIconSuccess"
            :before-upload="beforeIconUpload">
            <img alt="图片不存在" v-if="editForm.icon" :src="editForm.icon_url" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="游戏描述">
          <el-input v-model="editForm.desc" type="textarea"></el-input>
        </el-form-item>
        <el-form-item label="游戏URL">
          <el-input v-model="editForm.url" type="url"></el-input>
        </el-form-item>
        <el-form-item label="背景色值">
          1：<el-input v-model="editForm.background_color1" type="text" style="width: 200px"></el-input>
          2：<el-input v-model="editForm.background_color2" type="text" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="按钮">
          文本：<el-input v-model="editForm.btn_text" type="text" style="width: 200px"></el-input>
          色值：<el-input v-model="editForm.btn_color" type="text" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="友盟动作点位">
          <el-input v-model="editForm.umeng_action" type="text" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="排序">
          <el-input v-model="editForm.sort" type="number" value="1000" style="width: 200px"></el-input>
        </el-form-item>
        <el-form-item label="是否开启">
          <el-switch v-model="editForm.is_open_status"></el-switch>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button @click="editDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="updateWebGame">确 定</el-button>
  </span>
    </el-dialog>
  </div>
</template>

<script>

// 导入组件
export default {
  data () {
    return {
      // 获取用户列表的参数
      queryInfo: {
        // 当前的页数
        page: 1,
        limit: 10
      },
      list: [],
      total: 0,
      // 编辑表单数据
      editForm: {},
      // 控制编辑表单是否显示
      editDialogVisible: false,
      // cos文件上传参数
      cosParams: {},
      // cos文件上传url
      cosUploadUrl: ''
    }
  },
  created () {
    // 获取当前路由参数中的page
    const page = parseInt(this.$route.query.page ?? 1)
    this.queryInfo.page = page > 0 ? page : 1
    // 获取当前路由参数中的limit
    const limit = parseInt(this.$route.query.limit ?? 10)
    this.queryInfo.limit = (limit > 0 && limit < 100) ? limit : 10
    this.getList()
  },
  methods: {
    async getList () {
      const { data: res } = await this.$http.get('/task/webGameList', { params: this.queryInfo })
      if (res && res.code === 0) {
        this.list = res.data.data
        this.total = res.data.total
      }
    },
    // 监听pageSize的改变
    handleSizeChange (newSize) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          limit: newSize
        }
      })
      // 更新查询参数
      this.queryInfo.limit = newSize
      this.getList()
    },
    // 监听页码值的改变
    handleCurrentChange (newPage) {
      // 更新路由
      this.$router.push({
        path: this.$route.path,
        query: {
          ...this.$route.query,
          page: newPage
        }
      })
      // 更新查询参数
      this.queryInfo.page = newPage
      this.getList()
    },
    // 图标上传前回调
    async beforeIconUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      // 获取文件后缀名最为上传的文件类型
      const suffix = file.name.split('.').pop()
      // 获取cos上传凭证
      const { data: res } = await this.$http.get('/common/uploadToken?filetype=' + suffix)
      if (res && res.code === 0) {
        this.cosUploadUrl = `${res.data.upload_api}?token=${res.data.args.token}`
        // 更改文件上传key
        this.cosParams.key = res.data.args.key
      }

      return isLt2M
    },
    // 图标上传成功回调
    handleIconSuccess (res, file) {
      this.editForm.icon_url = URL.createObjectURL(file.raw)
      // 变更表单的图标
      this.editForm.icon = res.key
    },
    // 编辑对话框被关闭
    editDialogClose () {
    },
    // 编辑网页游戏
    editWebGame (info) {
      this.editDialogVisible = true
      this.editForm = info
    },
    // 更新网页游戏
    updateWebGame () {
      this.$refs.editFormRef.validate(async valid => {
        if (!valid) {
          return
        }
        // 发起添加的网络请求
        if (this.editForm.is_open_status) {
          this.editForm.is_open = 1
        } else {
          this.editForm.is_open = 0
        }
        const { data: res } = await this.$http.post('/task/updateWebGame', this.editForm)
        if (res && res.code === 0) {
          this.$message.success('修改成功')
          // 隐藏添加分类的对话框
          this.editDialogVisible = false
          await this.getList()
        } else {
          this.$message.error(res.message)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/* 图标上传组件 */
.avatar-uploader .avatar-uploader-icon {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .avatar-uploader-icon:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}

/*  tooltip组件 */
.tooltip_text {
  line-height: 20px
}
</style>
